<template>
    <div class="search-wrap">
        <div class="search-bar">
        <img src="../images/icon-search.png" />
        <input @blur="searchListen($event)" placeholder="输入搜索内容" :value="searchKeyInput"/>
        <span class="icon-guanbi iconfont close-btn" v-if="searchKeyInput" @click="cancelSearch"></span>
        </div>
        <span class="search-btn" v-if="isShowBtn" @click="searchPlay">搜索</span>
    </div>
</template>
<script>
export default {
  props: ['isShowBtn', 'searchKeyInput'],
  methods: {
    searchListen (e) {
      this.$emit('searchListen', e.target.value)
    },
    cancelSearch () {
      this.$emit('cancelSearch')
    },
    searchPlay () {
      this.$emit('searchPlay')
    }
  }
}
</script>

<style lang="scss" scoped>
.search-wrap{display: flex; justify-content: space-between; align-items: center; background:#fff; padding: 0.1rem; align-items: center; color:#333;}
.search-bar{flex:1; display: flex; align-items: center; height: .32rem; background:#f3f3f3; margin-right: .1rem; padding: 0 .1rem; border-radius: .1rem; line-height: .32rem}
.search-bar img{width:.18rem; height: .18rem; margin-right: .1rem}
.search-bar input{flex:1; border:none; background:none}
.search-bar input:focus{outline:none}
.icon-guanbi{color:#666}
</style>
